import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const ReviewDetailsContainer = styled(Grid)`
  padding-bottom: 21px;
`;
export const ReviewDetailsText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryDarkText};
  font-style: italic;
  letter-spacing: 0.02em;
  line-height: 16px;
`;
export const ReviewDetailsValue = styled(Typography)`
  color: ${selectedTheme.colors.primaryPurple};
  font-style: normal;
  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
`;
