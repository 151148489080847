import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  CheckButton,
  ItemDetailsCardContainer,
  OfferInfo,
  Info,
  DateButtonsContainer,
  ButtonsContainer,
  PostDate,
  CategoryIcon,
  SubcategoryIcon,
  QuantityIcon,
  EyeIcon,
  EditIconContainer,
  EditIcon,
  RemoveIconContainer,
  RemoveIcon,
  ButtonContainer,
  PinIconContainer,
  PinIcon,
  UnpinIcon,
  LocationIcon,
  // EditDeleteButtons,
} from "./ItemDetailsCard.styled";
import selectedTheme from "../../../themes";
import { useDispatch, useSelector } from "react-redux";
import { selectLatestChats } from "../../../store/selectors/chatSelectors";
import { increaseCounter } from "../../../store/actions/counter/counterActions";
import _ from "lodash";
import { selectUserId } from "../../../store/selectors/loginSelectors";
import { formatDateLocale } from "../../../util/helpers/dateHelpers";
import { startChat } from "../../../util/helpers/chatHelper";
import Information from "./OfferInformations/SingleInformation/SingleInformation";
import { useTranslation } from "react-i18next";
import OfferDetails from "./OfferDetails/OfferDetails";
import { useRouteMatch, useHistory } from "react-router-dom";
import itemDetailsData from "../../../notFoundData/itemDetailsData";
import {
  toggleDeleteOfferModal,
  toggleEditOfferModal,
} from "../../../store/actions/modal/modalActions";
import { getExistChat } from "../../../store/actions/chat/chatActions";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import { LOGIN_PAGE } from "../../../constants/pages";

const ItemDetailsCard = (props) => {
  // const offer = props.offer;
  const routeMatch = useRouteMatch();
  const chats = useSelector(selectLatestChats);
  const userId = useSelector(selectUserId);
  const history = useHistory();
  const { isLoggedIn } = useIsLoggedIn();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const offer = useMemo(() => {
    if (props.offer) {
      if (
        props.offer._id === routeMatch.params?.offerId &&
        !props?.createOffer
      ) {
        return props.offer;
      }
      if (props.createOffer) return props.offer.offer;
    }
    return itemDetailsData;
  }, [props.offer, props.createOffer, routeMatch.params]);

  const increaseOfferCounter = useMemo(() => {
    return _.once(function (id) {
      dispatch(increaseCounter(id));
    });
  }, []);

  useEffect(() => {
    if (offer?._id) {
      increaseOfferCounter(offer?._id);
    }
  }, [offer]);

  const date = formatDateLocale(new Date(offer?._created));

  const handleApiResponseSuccess = (value) => {
    if (value === false) {
      startChat(chats, offer, userId);
    } else {
      startChat(chats, offer, value._id);
    }
  };

  const startExchange = () => {
    if (!isLoggedIn) {
      history.push({
        pathname: LOGIN_PAGE,
      });
    } else {
      dispatch(
        getExistChat({
          userId: userId,
          offerId: props.offer._id,
          handleApiResponseSuccess,
        })
      );
    }
  };

  const showDeleteOfferModalHandler = () => {
    dispatch(
      toggleDeleteOfferModal({
        offer: offer,
        isAdmin: props.isAdmin,
      })
    );
  };

  const showEditOfferModalHandler = () => {
    dispatch(
      toggleEditOfferModal({
        editOffer: true,
        offer: offer,
        isAdmin: props.isAdmin,
        customUserId: offer?.user?._id,
      })
    );
  };
  const showPinOfferModalHandler = () => {
    dispatch(
      toggleDeleteOfferModal({
        offer: offer,
        pin: true,
        pinnedOffer: offer?.pinned,
        deleteOffer: false,
      })
    );
  };

  return (
    <>
      <ItemDetailsCardContainer
        sponsored={props.sponsored.toString()}
        halfwidth={props.halfwidth ? 1 : 0}
        className={props.className}
        singleOffer={props.singleOffer}
        previewCard={props.previewCard}
      >
        <OfferInfo previewCard={props.previewCard}>
          <Info>
            <Information
              icon={<CategoryIcon />}
              value={offer?.category?.name}
            />
            <Information
              icon={<SubcategoryIcon />}
              value={offer?.subcategory}
            />
            <Information icon={<QuantityIcon />} value={offer?.condition} />
            <Information
              icon={<LocationIcon />}
              value={props.previewCard ? offer?.location : offer?.location.city}
            />
            {!props.hideViews && (
              <Information icon={<EyeIcon />} value={offer?.views?.count} />
            )}
          </Info>
          <PostDate previewCard={props.previewCard}>{date}</PostDate>
        </OfferInfo>
        <OfferDetails
          offer={offer}
          createOffer={props.createOffer}
          showExchangeButton={props.showExchangeButton}
          showPublishButton={props.showPublishButton}
          singleOffer={props.singleOffer}
          previewCard={props.previewCard}
        />

        {!props.halfwidth && props.showExchangeButton && (
          <ButtonContainer>
            <DateButtonsContainer>
              {props.isMyOffer && (
                <ButtonsContainer>
                  {props?.isAdmin && (
                    <PinIconContainer onClick={showPinOfferModalHandler}>
                      {offer?.pinned ? <UnpinIcon /> : <PinIcon />}
                    </PinIconContainer>
                  )}
                  <EditIconContainer onClick={showEditOfferModalHandler}>
                    <EditIcon />
                  </EditIconContainer>
                  <RemoveIconContainer onClick={showDeleteOfferModalHandler}>
                    <RemoveIcon />
                  </RemoveIconContainer>
                </ButtonsContainer>
              )}
            </DateButtonsContainer>
            <CheckButton
              variant={props.sponsored ? "contained" : "outlined"}
              buttoncolor={selectedTheme.colors.primaryPurple}
              textcolor={
                props.sponsored ? "white" : selectedTheme.colors.primaryPurple
              }
              onClick={startExchange}
              disabled={props.isMyOffer}
            >
              {t("itemDetailsCard.startExchangeButton")}
            </CheckButton>
          </ButtonContainer>
        )}
      </ItemDetailsCardContainer>
    </>
  );
};

ItemDetailsCard.propTypes = {
  halfwidth: PropTypes.bool,
  sponsored: PropTypes.bool,
  offer: PropTypes.any,
  hideViews: PropTypes.bool,
  showExchangeButton: PropTypes.bool,
  showBarterButton: PropTypes.bool,
  showPublishButton: PropTypes.bool,
  className: PropTypes.string,
  singleOffer: PropTypes.bool,
  isMyOffer: PropTypes.bool,
  previewCard: PropTypes.bool,
  createOffer: PropTypes.bool,
  isAdmin: PropTypes.bool,
};
ItemDetailsCard.defaultProps = {
  halfwidth: false,
  sponsored: false,
  showExchangeButton: true,
  isAdmin: false,
};

export default ItemDetailsCard;
