import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import selectedTheme from "../../../../../themes";
import { ReactComponent as Edit } from "../../../../../assets/images/svg/edit.svg";
import { ReactComponent as Block } from "../../../../../assets/images/svg/block.svg";
import { ReactComponent as Unblock } from "../../../../../assets/images/svg/unblock.svg";
import { ReactComponent as Remove } from "../../../../../assets/images/svg/trash.svg";

export const ButtonContainer = styled(Box)`
  width: 40px;
  height: 40px;
  font-weight: 900;
  background: ${(props) =>
    props.disabled ? "white" : selectedTheme.colors.primaryIconBackgroundColor};
  border-radius: 360px;
  padding: 0.45rem 0.45rem 0.27rem 0.57rem;
  cursor: ${(props) => !props.disabled && `pointer`};
  ${(props) =>
    props.disabled &&
    css`
      & path {
        stroke: ${selectedTheme.colors.iconStrokePurpleDisabledColor};
      }
    `}
  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
`;

export const UnblockIcon = styled(Unblock)`
  position: relative;
  top: 3px;
  left: 2px;
  width: 18px;
  height: 18px;
  @media (max-width: 600px) {
    top: 0;
    left: -2px;
  }
`;
export const BlockIcon = styled(Block)`
  position: relative;
  top: 3px;
  left: 2px;
  width: 18px;
  height: 18px;
  @media (max-width: 600px) {
    top: 0;
    left: -2px;
  }
  & path {
    stroke: ${selectedTheme.colors.primaryPurple};
  }
`;
export const RemoveIcon = styled(Remove)`
  position: relative;
  top: 3px;
  left: 2px;
  width: 18px;
  height: 18px;
  @media (max-width: 600px) {
    top: 0;
    left: -2px;
  }
  & path {
    stroke: ${selectedTheme.colors.primaryPurple};
  }
`;
export const EditIcon = styled(Edit)`
  position: relative;
  top: 3px;
  left: 2px;
  width: 18px;
  height: 18px;
  @media (max-width: 600px) {
    top: 0;
    left: -2px;
  }
  & path {
    stroke: ${selectedTheme.colors.primaryPurple};
  }
`;
