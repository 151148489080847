import React from "react";
import PropTypes from "prop-types";
import {
  PIBContainer,
  PIBIcon,
  PIBText,
  UserImage,
  UserLabeledCardContainer,
  UserLabeledCardDetailsContainer,
  UserName,
} from "./UserLabeledCard.styled";
import { getImageUrl, variants } from "../../../../util/helpers/imageUrlGetter";
import { useTranslation } from "react-i18next";

const UserLabeledCard = (props) => {
  const { t } = useTranslation();
  return (
    <UserLabeledCardContainer>
      <UserImage src={getImageUrl(props.user.image, variants.reviewCard)} />
      <UserLabeledCardDetailsContainer>
        <UserName>{props.user.company.name}</UserName>
        <PIBContainer>
          <PIBIcon />
          <PIBText>{t("itemDetailsCard.PIB") + props.user.company.PIB}</PIBText>
        </PIBContainer>
      </UserLabeledCardDetailsContainer>
    </UserLabeledCardContainer>
  );
};

UserLabeledCard.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
};

export default UserLabeledCard;
