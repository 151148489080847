export const primaryThemeColors = {
  primaryPurple: "#5A3984",
  primaryPurpleAnimation: "#4B2C73",
  primaryYellow: "#f7b126",
  primaryPurpleDisabled: "#E4E4E4",
  primaryBorderDisabled: "#D4D4D4",
  primaryBackgroundColor: "#F1F1F1",
  primaryTextDisabled: "#B4B4B4",
  primaryText: "#4D4D4D",
  primaryGrayText: "#818181",
  primaryDarkGrayText: "#DCDCDC",
  primaryIconBackgroundColor: "#E4E4E4",
  borderNormal: "#D4D4D4",
  primaryDarkTextSecond: "#E4E4E4",
  borderSponsoredColor: "#E5D0FF",
  backgroundSponsoredColor: "#F5EDFF",
  offerBackgroundColor: "#F5F5F5",
  selectOptionTextColor: "#1D1D1D",
  yellowButtonTextColor: "#1D1D1D",
  primaryDarkText: "#505050",
  primaryDarkTextThird: "#4D4D4D",
  iconStrokeColor: "#8C8C8C",
  iconStrokeDisabledColor: "#C4C4C4",
  iconStrokePurpleDisabledColor: "#B4B4B4",
  imagePickerBackground: "#E4E4E4",
  iconYellowColor: "#FEB005",
  iconMineProfileColor: "#9E9E9E",
  iconProfileColor: "#C4C4C4",
  skeletonItemColor: "#F4F4F4",
  chatHeaderColor: "#F4F4F4",
  messageBackground: "#F4F4F4",
  stylingTextBackground: "#F4F4F4",
  messageText: "#1D1D1D",
  messageDate: "#949494",
  messageMyDate: "#C4C4C4",
  filterSkeletonBackground: "#E4E4E4",
  filterSkeletonBackgroundSecond: "#D4D4D4",
  filterSkeletonItems: "#D4D4D4",
  filterSkeletonItemsSecond: "#DDDDDD",
  staticBackgroundColor: "#F3EFF8",
  stepProgressAltColor: "#F4F4F4",
  blockedColor: "#E4E4E4",
  blockedTextColor: "#D13333",
  errorColor: "#d32f2f",
  colorPicker: {
    darkGray: "#4D4D4D",
    gray: "#B4B4B4",
    yellow: "#FEB005",
    purple: "#5A3984",
    pink: "#E5D0FF",
    border: "#818181",
  },
};
