import React from "react";
import PropTypes from "prop-types";
import { FieldLabel } from "../SecondPartCreateOffer.styled";
import {
  SelectAltText,
  SelectField,
  SelectText,
} from "../../CreateOffer.styled";
import { SelectOption } from "../../FirstPart/FirstPartCreateOffer.styled";
import { conditionSelectEnum } from "../../../../../enums/conditionEnum";
import { useTranslation } from "react-i18next";
import { InputButtonContainer } from "./OfferConditionField.styled";

const OfferConditionField = (props) => {
  const { t } = useTranslation();
  const formik = props.formik;
  return (
    <InputButtonContainer>
      <FieldLabel leftText={t("offer.condition")} />
      <SelectField
        onChange={(event) => {
          formik.setFieldValue("condition", event.target.value);
        }}
        value={formik.values.condition}
      >
        <SelectOption style={{ display: "none" }} value="default">
          {t("offer.choseCondition")}
        </SelectOption>
        {Object.keys(conditionSelectEnum).map((key) => {
          var item = conditionSelectEnum[key];
          return (
            <SelectOption value={item.mainText} key={item.value}>
              <SelectText>{item.mainText}</SelectText>
              <SelectAltText>{item.altText}</SelectAltText>
            </SelectOption>
          );
        })}
      </SelectField>
    </InputButtonContainer>
  );
};

OfferConditionField.propTypes = {
  formik: PropTypes.any,
};

export default OfferConditionField;
