import { Container, Grid } from "@mui/material";
import styled from "styled-components";

export const MainLayoutContainer = styled(Container)`
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    width: 100%;
    max-width: none;
    display: flex;
    flex: 1;
    height: 100%;
    margin-top: 80px;
    @media (max-width: 600px) {
        margin-top: 100px;
    }
`

export const LeftCard = styled(Grid)`
    margin-top: 30px;
    border-top-right-radius: 4px;
`
export const Content = styled(Grid)`
`