import { Container, Grid } from "@mui/material";
import styled from "styled-components";

export const ProfileLayoutContainer = styled(Container)`
  padding-left: 36px;
  padding-right: ${(props) => (props.singleOffer ? "76px" : 0)};
  margin: 0;
  width: 100%;
  max-width: none;
  /* display: flex; */
  position: relative;
  /* flex: 1; */
  height: 100%;
  @media (max-width: 1350px) {
    padding-right: ${(props) => (props.isAdmin ? "36px" : "0")};
  }
  @media (max-width: 600px) {
    padding-left: 0;
    padding-right: 18px;
  }
`;

export const ContentRightCardContainer = styled(Grid)`
  margin: 0;
  width: 100%;
`;

export const RightCard = styled(Grid)`
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
  ${(props) => props.profile && `min-width: 350px;`}

  @media screen and (min-width: 600px) {
    margin-top: 34px;
    margin-left: ${(props) => (props.profile ? "0" : "36px")};
    padding-left: ${(props) => (props.singleOffer ? "36px" : 0)};
    border-top-right-radius: 4px;
    min-width: 0;
    ${(props) => props.singleOffer && `width: 100%`}
  }
  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
  @media (max-width: 600px) {
    width: 100%;
    min-width: 0;
  }
`;
export const Content = styled(Grid)`
  width: 100%;
`;
