import { Box, Container, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { Icon } from "../../Icon/Icon";
import { ReactComponent as Eye } from "../../../assets/images/svg/eye-striked.svg";
import { ReactComponent as Remove } from "../../../assets/images/svg/trash.svg";
import { ReactComponent as Edit } from "../../../assets/images/svg/edit.svg";
import { ReactComponent as Like } from "../../../assets/images/svg/like.svg";
import { ReactComponent as Pin } from "../../../assets/images/svg/pin.svg";
import { ReactComponent as PinOutlined } from "../../../assets/images/svg/pin-outlined.svg";
import { ReactComponent as UnpinOutlined } from "../../../assets/images/svg/unpin-outlined.svg";
import { ReactComponent as Category } from "../../../assets/images/svg/category.svg";
import { ReactComponent as Calendar } from "../../../assets/images/svg/calendar.svg";
import { ReactComponent as Accept } from "../../../assets/images/svg/accept.svg";

export const OfferCardContainer = styled(Container)`
  display: ${(props) => (props.skeleton ? "none" : "flex")};
  flex-direction: column;
  width: ${(props) => (!props.halfwidth ? "100%" : "49%")};
  box-sizing: border-box;
  margin: 10px 0;
  background-color: ${(props) =>
    props.sponsored === "true"
      ? selectedTheme.colors.backgroundSponsoredColor
      : "white"};
  border-radius: 4px;
  ${(props) =>
    props.sponsored === "true" &&
    `border: 1px solid ${selectedTheme.colors.borderSponsoredColor};`}
  padding: 16px;
  max-width: 2000px;
  height: 180px;
  position: relative;
  cursor: pointer;
  @media (max-width: 550px) {
    height: 194px;
    padding: 18px;
    padding-top: 12px;
    ${(props) =>
      props.vertical &&
      `
      height: 400px;
      width: 180px;
      margin: 0 18px;
    `}
  }
`;
export const OfferFlexContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  max-height: 184px;
  @media (max-width: 600px) {
    ${(props) =>
      props.vertical &&
      `
      flex-direction: column;
    `}
  }
`;
export const OfferImage = styled.img`
  max-width: 144px;
  max-height: 144px;
  width: 144px;
  height: 144px;
  @media (max-width: 600px) {
    ${(props) =>
      !props.vertical &&
      `
      max-width: 108px;
    max-height: 108px;
    width: 108px;
    height: 108px;
    `}
  }
`;
export const OfferInfo = styled(Box)`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 18px;
  ${(props) =>
    props.vertical &&
    `
    margin-left: 0;
    margin-top: 5px;
  `}
`;
export const OfferTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  flex: 1;
  color: ${selectedTheme.colors.primaryPurple};
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  overflow: hidden;
  line-height: 33px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 33px;
  margin-bottom: 28px;
  max-width: calc(100% - 105px);
  @media (max-width: 550px) {
    width: 100%;
    width: calc(100% - 60px);
    font-size: 18px;
    display: none;
    ${(props) =>
      props.vertical &&
      `
      display: flex;
      flex-basis: 44px;
      height: 44px;
      max-height: 44px;
      position: relative;
      line-height: 22px; 
      margin-top: 5px;
      font-size: 18px;
      max-width: none;
      margin-bottom: 0px !important;

    `}
  }
  @media (max-width: 800px) {
    margin-bottom: 16px;
  }
`;
export const OfferAuthor = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${(props) => props.vertical && `height: 36px; flex: initial;`}
`;
export const OfferAuthorName = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  line-height: 22px;
  font-size: 16px;
  color: ${selectedTheme.colors.primaryText};
  @media (max-width: 600px) {
    font-size: 14px;
    ${(props) =>
      props.vertical &&
      `
        line-height: 19px;
        font-size: 14px;
    `}
  }
`;
export const OfferLocation = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryDarkText};
  line-height: 16px;
  font-size: 12px;
  ${(props) =>
    props.vertical &&
    `
    font-size: 12px;
    margin-top: -17px;
  `}
`;
export const OfferDetails = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => (!props.halfwidth ? "no-wrap" : "wrap")};
  justify-content: start;
  gap: 1rem;
  /* @media (max-width: 1150px) {
    flex-direction: column;
    justify-content: center;
    gap: 0;
  } */
  @media (max-width: 900px) {
    gap: 1rem;
    justify-content: start;
    flex-direction: row;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }
`;
export const OfferCategory = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  ${(props) =>
    props.vertical &&
    `
    // position: absolute;
    // bottom: 60px;
  `}
`;
export const OfferPackage = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
`;
export const OfferViews = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  ${(props) =>
    props.vertical &&
    `
    display: none;
  `}
`;
export const OfferDate = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  margin-top: 1px;
  @media (max-width: 600px) {
    ${(props) =>
      props.vertical &&
      `
      margin-top: -18px;
    `}
  }
`;

export const Line = styled(Box)`
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 100px;
  width: 0;
  margin: auto 0;
  @media (max-width: 1500px) {
    display: none;
  }
`;
export const DetailIcon = styled(Icon)`
  & svg {
    width: 14px;
    position: relative;
    top: -1px;
  }
`;
export const DetailText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  line-height: 16px;
  font-size: 12px;
  position: relative;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 16px;
  top: -2px;
  left: 3px;
`;
export const MessageIcon = styled(IconButton)`
  ${(props) => !props.showMessageIcon && "display: none;"}
  width: 40px;
  height: 40px;
  background-color: ${selectedTheme.colors.primaryIconBackgroundColor};
  border-radius: 100%;
  padding-top: 2px;
  text-align: center;
  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
    padding: 0;
    ${(props) =>
      props.vertical &&
      `
      display: none;
    `}
    & button svg {
      width: 16px;
      height: 16px;
      position: relative;
      top: -3px;
      left: -3.5px;
    }
  }
`;
export const OfferImageContainer = styled(Box)`
  min-width: 144px;
  min-height: 144px;
  width: 144px;
  height: 144px;
  border-radius: 4px;
  overflow: hidden;
  @media (max-width: 600px) {
    ${(props) =>
      !props.vertical
        ? `
      min-width: 108px;
      min-height: 108px;
      width: 108px;
      height: 108px;
    `
        : `margin-top: 4px;`}
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.12);
  }
`;
export const OfferTitleAboveImage = styled(OfferTitle)`
  padding-bottom: 18px;
  padding-top: 5px;
  padding-left: 1px;
  display: block;
  width: calc(100% - ${(props) => props.noOfButtons} * 30px - 10px);
  max-width: calc(100% - ${(props) => props.noOfButtons} * 30px - 10px);
  height: 40px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${(props) => props.vertical && `display: none;`}
  @media (min-width: 551px) {
    display: none;
  }
`;
export const EyeIcon = styled(Eye)`
  width: 12px;
  height: 11px;
  position: relative;
  top: 1px !important;
`;
export const RemoveIconContainer = styled(MessageIcon)`
  display: block;

  @media screen and (max-width: 600px) {
    display: block;
    ${(props) =>
      props.vertical &&
      `
    `}
  }
`;
export const RemoveIcon = styled(Remove)``;
export const EditIconContainer = styled(MessageIcon)`
  display: block;

  @media screen and (max-width: 600px) {
    display: block;
    ${(props) =>
      props.vertical &&
      `
    `}
  }
`;
export const EditIcon = styled(Edit)``;
export const LikeIconContainer = styled(MessageIcon)`
  display: block;
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
  ${(props) =>
    props.disabled &&
    `
    cursor: initial;
    & button {
      cursor: initial;
    }
  `}
`;
export const AcceptIconContainer = styled(MessageIcon)`
  display: block;
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
  ${(props) =>
    props.disabled &&
    `
    cursor: initial;
    & button {
      cursor: initial;
    }
  `}
`;
export const PinIconContainer = styled(MessageIcon)`
  @media (max-width: 600px) {
    ${(props) =>
      props.vertical &&
      `
      display: block;
      & button svg {
        width: 19px;
        height: 19px;
      }
    `}
  }
`;
export const LikeIcon = styled(Like)`
  & path {
    stroke: ${(props) =>
      props.disabled
        ? selectedTheme.colors.primaryPurple
        : selectedTheme.colors.primaryPurple};
  }
  @media (max-width: 600px) {
    position: relative;
    top: -1px;
  }
`;
export const AcceptIcon = styled(Accept)`
  & path {
    fill: ${(props) =>
      props.disabled
        ? selectedTheme.colors.primaryPurple
        : selectedTheme.colors.primaryPurple};
  }
  @media (max-width: 600px) {
    position: relative;
    top: -1px;
  }
`;
export const PinOutlinedIcon = styled(PinOutlined)`
  & g path {
    /* fill: transparent !important; */
    /* stroke-width: 2; */
    /* stroke: ${selectedTheme.colors.primaryPurple} !important; */
  }
`;
export const UnpinOutlinedIcon = styled(UnpinOutlined)``;
export const PinIcon = styled(Pin)`
  position: relative;
  top: 6px;
`;
export const CategoryIcon = styled(Category)`
  width: 14px;
  & path {
    stroke-width: 1;
  }
`;

export const CalendarIcon = styled(Calendar)`
  width: 14px;
  & path {
    stroke-width: 1;
  }
`;
export const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 18px;
  position: absolute;
  top: 18px;
  right: 18px;
  @media (max-width: 600px) {
    gap: 9px !important;
    ${(props) =>
      props.vertical &&
      css`
        flex-direction: row-reverse;
        top: initial;
        right: initial;
        bottom: 18px;
        left: 18px;
      `}
  }
`;

export const TooltipInnerContainer = styled(Box)``;
