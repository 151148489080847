import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const OfferDescriptionText = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.primaryDarkText};
  line-height: 22px;
  max-width: calc(100% - 230px);
  max-height: 120px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  pointer-events: none;
  @media (max-width: 1500px) {
    display: none;
  }
`;
export const OfferDescriptionContainer = styled(Box)`
  flex: 3;
  margin: auto 0;
  padding-left: 35px;
  @media (max-width: 1500px) {
    display: none;
  }
`;
export const OfferDescriptionTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryDarkText};
  line-height: 16px;
`;
