import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const OfferCardContainerMobile = styled(Box)`
  display: none;

  @media (max-width: 550px) {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
`;

export const OfferTitleMobile = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  display: none;
  flex: 1;
  color: ${selectedTheme.colors.primaryPurple};
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  @media (max-width: 550px) {
    display: block;
    ${(props) =>
      props.vertical &&
      `
      display: flex;
      flex: none;
      position: relative;
      line-height: 22px; 
      margin-top: 5px;
      font-size: 18px;

    `}
  }
`;
export const OfferTextMobile = styled(Box)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 9px;
  color: ${selectedTheme.colors.primaryText};
`;