import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const ReviewOfferContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;
export const ReviewOfferImage = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 2px;
`;
export const ReviewOfferDetails = styled(Box)`
    display: flex;
    flex-direction: column;
    padding-top: 6px;
`;
export const ReviewOfferDescription = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  color: ${selectedTheme.colors.primaryDarkText};
`;
export const ReviewOfferTitle = styled(Typography)`
  color: ${selectedTheme.colors.primaryPurple};
  font-size: 16px;
  font-weight: 600;
  font-family: ${selectedTheme.fonts.textFont};
  overflow: hidden;
  line-height: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 19px;
  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    max-height: 16px;
  }
  
`;
