import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const ChatCardContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  height: 180px;
  width: ${(props) => (!props.halfwidth ? "100%" : "49%")};
  box-sizing: border-box;
  margin: 10px 0;
  background-color: ${(props) =>
    props.sponsored === "true"
      ? selectedTheme.colors.backgroundSponsoredColor
      : "white"};
  border-radius: 4px;
  ${(props) =>
    props.sponsored === "true" &&
    `border: 1px solid ${selectedTheme.colors.borderSponsoredColor};`}
  padding: 16px;
  max-width: 2000px;
  height: 180px;
  position: relative;
  gap: 18px;

  @media (max-width: 550px) {
    max-height: 108px;
    margin: 0;
    ${(props) =>
      props.vertical &&
      `height: 330px;
      width: 180px;
      margin: 0 18px;`}
  }
`;
export const UserImage = styled.img`
  border-radius: 50%;
  width: 144px;
  height: 144px;
  @media (max-width: 600px) {
    width: 72px;
    height: 72px;
  }
`;
export const ProfileNameContainer = styled(Box)`
  max-height: 64px;
  min-height: 48px;
`
export const UserImgWrapper = styled(Box)`
  overflow: hidden;
  border-radius: 50%;
  width: 144px;
  height: 144px;
  @media (max-width: 600px) {
    width: 72px;
    height: 72px;
    min-width: 80px;
  }
`;
export const ChatInfo = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;
export const UserName = styled(Typography)`
  /* margin-bottom: 12px; */
  max-height: 48px;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
