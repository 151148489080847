import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../themes";
import { AccountCircle } from "@mui/icons-material";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { UserButtonContainer, UserName } from "./UserButton.styled";
import PopoverComponent from "../../Popovers/PopoverComponent";
import { MyProfile } from "../../Popovers/MyProfile/MyProfile";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UserButton = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [userPopoverOpen, setUserPopoverOpen] = useState(false);
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  useEffect(() => {
    setUserPopoverOpen(false);
  }, [location.pathname]);

  const openUserPopover = (event) => {
    setUserPopoverOpen(true);
    setUserAnchorEl(event.currentTarget);
  };
  const closeUserPopover = () => {
    setUserPopoverOpen(false);
    setUserAnchorEl(null);
  };
  return (
    <>
      <UserButtonContainer onClick={openUserPopover}>
        <UserName>{props.name}</UserName>
        <IconButton
          aria-label={t("header.myProfile")}
          style={{
            background: selectedTheme.colors.primaryIconBackgroundColor,
            color: selectedTheme.colors.primaryPurple,
          }}
        >
          <AccountCircle onClick={openUserPopover} />
        </IconButton>
      </UserButtonContainer>
      <PopoverComponent
        anchorEl={userAnchorEl}
        open={userPopoverOpen}
        onClose={() => {
          setUserPopoverOpen(false);
          setUserAnchorEl(null);
        }}
        content={<MyProfile closePopover={closeUserPopover} />}
      />
    </>
  );
};

UserButton.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
};

export default UserButton;
