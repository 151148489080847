import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import { ReactComponent as Close } from "../../../../assets/images/svg/close-black.svg";


export const FilterHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
export const Title = styled(Typography)`
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  position: relative;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
export const CloseIcon = styled(Close)`

`