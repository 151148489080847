import { format } from "date-fns";
import { enUS, sr } from "date-fns/locale";
import i18next from "i18next";

export function formatDate(date, fmt = "MM/dd/y", locale = sr) {
  const dt = new Date(date);
  return format(dt, fmt, { locale });
}

export function formatDateTime(date, locale = enUS) {
  const dt = new Date(date);
  return format(dt, "MM.dd.y hh:mm"), { locale };
}

export function getDateDay(date) {
  const dt = new Date(date);
  return format(dt, "dd");
}

export function getDateMonth(date) {
  const dt = new Date(date);
  return format(dt, "MM");
}

export function getDateYear(date) {
  const dt = new Date(date);
  return format(dt, "y");
}

export function formatDateTimeLocale(date) {
  const dayCreated =
    date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const monthCreated =
    date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  const yearCreated = date.getFullYear();
  const hourCreated =
    date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const minutesCreated =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  return `${dayCreated}.${monthCreated}.${yearCreated} ${hourCreated}:${minutesCreated}`;
}

// TODO add locale
export function formatDateRange(dates) {
  const start = formatDate(dates.start);
  const end = formatDate(dates.end);
  return i18next.t("common.date.range", { start, end });
}

export function formatDateLocale(date) {
  const dayCreated =
    date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const monthCreated =
    date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  const yearCreated = date.getFullYear();
  return `${dayCreated}.${monthCreated}.${yearCreated}.`;
}
export function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}
export function convertLocalDateToUTCDate(date) {
  var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours + offset);

  return newDate;
}
export function getMessageDate(date) {
  const blankTime = new Date(date.toISOString());
  const newDate = convertUTCDateToLocalDate(blankTime);
  return formatDateTimeLocale(newDate);
}
