import { Box, Link, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as CategoryHeader } from "../../../../assets/images/svg/category-header.svg";
import selectedTheme from "../../../../themes";

export const TooltipInnerContainer = styled(Box)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  & * {
    display: inline;
  }
`;
export const CategoryHeaderIcon = styled(CategoryHeader)`
  position: relative;
  top: 4px;
  @media (max-width: 600px) {
    width: 12px;
    height: 12px;
    top: 1px;
  }
`;
export const HeaderLocation = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
  font-weight: 700;
  line-height: 22px;
  font-size: 16px;
  flex: 2;
  margin-left: 9px;
  max-width: 50%;
  position: relative;
  top: -2px;

  &:after {
    content: ${(props) => (props.initial ? `":"` : `""`)};
    @media (max-width: 600px) {
      content: "";
    }
  }
  @media (max-width: 600px) {
    font-size: 12px;
    padding-top: 3px;
  }
`;

export const HeaderCategoryString = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
  font-weight: 700;
  line-height: 22px;
  font-size: 16px;
  /* position: relative;
  bottom: 2px; */
  cursor: pointer;
  @media (max-width: 600px) {
    font-size: 12px;
    padding-top: 3px;
  }
`;
export const HeaderSubcategoryString = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryPurple};
  font-weight: 700;
  line-height: 22px;
  font-size: 16px;
  /* position: relative;
  bottom: 2px; */
  cursor: pointer;
  @media (max-width: 600px) {
    font-size: 12px;
    padding-top: 3px;
  }
`;
export const HeaderLocationsString = styled(Typography)`
  /* position: relative;
  bottom: 2px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 600px) {
    font-size: 12px;
    padding-top: 3px;
  }
  max-width: 100%;
`;
export const HeaderLocationsMainString = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  max-width: 200px;
  color: ${selectedTheme.colors.primaryPurple};
  font-weight: 700;
  line-height: 22px;
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 12px;
    padding-top: 3px;
  }
`;
export const HeaderCompanyString = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  max-width: 200px;
  color: ${selectedTheme.colors.primaryPurple};
  font-weight: 700;
  line-height: 22px;
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 12px;
    padding-top: 3px;
  }
`;
export const HeaderAltLocation = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.primaryText};
  position: relative;
  top: 0.5px;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const HeaderTitleContainer = styled(Box)`
  position: relative;
  left: 5px;
`;
export const HeaderTitleText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  color: ${selectedTheme.colors.primaryText};
  font-size: 16px;
  position: relative;
  bottom: 2px;
  left: 2px;
`;
export const ButtonContainer = styled(Link)`
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  min-width: 200px;
`;
export const HeaderText = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  line-height: 22px;
  font-size: 16px;
  color: ${selectedTheme.colors.primaryPurple};
  border-bottom: 1px dotted ${selectedTheme.colors.primaryPurple};
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
