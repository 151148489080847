import React from "react";
import PropTypes from "prop-types";
import {
  ContentRightCardContainer,
  Content,
  RightCard,
  ProfileLayoutContainer,
} from "./ProfileLayout.styled";

const ProfileLayout = (props) => {
  return (
    <ProfileLayoutContainer
      isAdmin={props.isAdmin}
      singleOffer={props.singleOffer}
      profile={props.profile}
    >
      {props.children}
      <ContentRightCardContainer container spacing={2}>
        <Content sm={12} md={8} lg={8} xl={9} item>{props.content}</Content>
        <RightCard sm={12} md={4} lg={4} xl={3} item singleOffer={props.singleOffer} profile={props.profile}>
          {props.rightCard}
        </RightCard>
      </ContentRightCardContainer>
    </ProfileLayoutContainer>
  );
};

ProfileLayout.propTypes = {
  children: PropTypes.node,
  leftCard: PropTypes.node,
  content: PropTypes.node,
  rightCard: PropTypes.node,
  singleOffer: PropTypes.bool,
  profile: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default ProfileLayout;
