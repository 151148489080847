import { Box } from "@mui/material";
import styled from "styled-components";

export const InputButtonContainer = styled(Box)`
  width: 332px;
  margin: 25px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 360px) {
    width: 260px;
  }
`;
