import React from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../../../themes";
import { replaceInRoute } from "../../../../util/helpers/routeHelpers";
import { ITEM_DETAILS_PAGE } from "../../../../constants/pages";
import { useTranslation } from "react-i18next";
import { CheckButtonContainer } from "./CheckButton.styled";
import history from "../../../../store/utils/history";

const CheckButton = (props) => {
  const { t } = useTranslation();
  const routeToItem = (event, itemId) => {
    event.stopPropagation();
    if (!props?.disabled)
      history.push(
        replaceInRoute(ITEM_DETAILS_PAGE, {
          offerId: itemId,
        })
      );
  };
  return (
    <CheckButtonContainer
      variant={props.sponsored ? "contained" : "outlined"}
      buttoncolor={selectedTheme.colors.primaryPurple}
      textcolor={props.sponsored ? "white" : selectedTheme.colors.primaryPurple}
      style={{ fontWeight: "600" }}
      onClick={(event) => routeToItem(event, props.offerId)}
      disabled={props.disabled}
    >
      {t("offer.checkButtonLabel")}
    </CheckButtonContainer>
  );
};

CheckButton.propTypes = {
  sponsored: PropTypes.bool,
  offerId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CheckButton;
