import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  FormContainer,
  // RegisterDescription,
} from "./SecondPartOfRegistration.styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TextField } from "../../../../components/TextFields/TextField/TextField";
import { PrimaryButton } from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import selectedTheme from "../../../../themes";

const SecondPartOfRegistration = (props) => {
  const { t } = useTranslation();
  const [PIBTakenStatus, setPIBTakenStatus] = useState(false);

  useEffect(() => {
    if (props.error.length > 0) {
      setPIBTakenStatus(true);
    }
  }, [props.error]);

  useEffect(() => {
    if (props.informations?.nameOfFirm) {
      formik.setFieldValue("nameOfFirm", props.informations?.nameOfFirm);
      formik.setFieldValue("PIB", props.informations.PIB);
    }
  }, [props.informations]);

  const formik = useFormik({
    initialValues: {
      nameOfFirm: "",
      PIB: "",
    },
    validationSchema: Yup.object().shape({
      nameOfFirm: Yup.string().required(t("login.usernameRequired")),
      PIB: Yup.string()
        .required(t("login.passwordRequired"))
        .min(9, t("register.PIBnoOfCharacters"))
        .max(9, t("register.PIBnoOfCharacters")),
    }),
    onSubmit: props.handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <FormContainer component="form" onSubmit={formik.handleSubmit}>
      {/* <RegisterDescription component="p" variant="p">
        {t("register.descriptionSecond")}
      </RegisterDescription> */}

      <TextField
        name="nameOfFirm"
        placeholder={t("common.labelFirm")}
        margin="normal"
        value={formik.values.nameOfFirm}
        onChange={formik.handleChange}
        error={
          (formik.touched.nameOfFirm && Boolean(formik.errors.nameOfFirm)) ||
          props?.companyNameError
        }
        helperText={formik.touched.nameOfFirm && formik.errors.nameOfFirm}
        autoFocus
        fullWidth
      />

      <TextField
        name="PIB"
        placeholder={t("common.labelPIB")}
        margin="normal"
        type="number"
        value={formik.values.PIB}
        onChange={(event) => {
          formik.setFieldValue("PIB", event.target.value);
        }}
        error={
          (formik.touched.PIB && Boolean(formik.errors.PIB)) || PIBTakenStatus
        }
        helperText={formik.touched.PIB && formik.errors.PIB}
        fullWidth
      />
      {formik.errors.PIB && formik.touched.PIB && (
        <ErrorMessage>{formik.errors.PIB}</ErrorMessage>
      )}
      {(props.error || props?.companyNameError) && (
        <ErrorMessage>
          {props.errorMessage || props?.companyNameError}
        </ErrorMessage>
      )}

      <PrimaryButton
        type="submit"
        variant="contained"
        height="48px"
        fullWidth
        buttoncolor={selectedTheme.colors.primaryPurple}
        textcolor="white"
        disabled={
          formik.values.PIB.length === 0 ||
          formik.values.nameOfFirm.length === 0 ||
          formik.values.PIB.toString() === props.error
        }
      >
        {t("common.continue")}
      </PrimaryButton>
    </FormContainer>
  );
};

SecondPartOfRegistration.propTypes = {
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  informations: PropTypes.any,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  companyNameError: PropTypes.string,
};

export default SecondPartOfRegistration;
