import { all, takeLatest, call, put } from "@redux-saga/core/effects";
import { JWT_REFRESH_TOKEN, JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import { attemptLogin } from "../../request/loginRequest";
import { attemptFetchProfile } from "../../request/profileRequest";
import { attemptRegister } from "../../request/registerRequest";
import { authScopeSetHelper } from "../../util/helpers/authScopeHelpers";
import {
  fetchUserError,
  fetchUserSuccess,
} from "../actions/login/loginActions";
import { setMineProfile } from "../actions/profile/profileActions";
import { REGISTER_USER_FETCH } from "../actions/register/registerActionConstants";
import jwt from "jsonwebtoken";
import {
  fetchRegisterUserError,
  fetchRegisterUserSuccess,
} from "../actions/register/registerActions";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import i18next from "i18next";

function* fetchRegisterUser({ payload }) {
  try {
    const requestBody = new FormData();
    requestBody.append("email", payload.values.mail);
    requestBody.append("password", payload.values.registerPassword);
    if (payload.values.image) {
      requestBody.append("file", payload.values.image);
    }
    requestBody.append("company[name]", payload.values.nameOfFirm);
    requestBody.append("company[PIB]", payload.values.PIB);
    if (payload.values.phoneNumber.toString().length !== 0)
      requestBody.append(
        "company[contacts][telephone]",
        payload.values.phoneNumber
      );
    if (payload.values.location.toString().length !== 0)
      requestBody.append(
        "company[contacts][location]",
        payload.values.location
      );
    if (payload.values.website.toString().length !== 0)
      requestBody.append("company[contacts][web]", payload.values.website);
    yield call(attemptRegister, requestBody);

    const { data } = yield call(attemptLogin, {
      email: payload.values.mail,
      password: payload.values.registerPassword,
    });
    if (data.token) {
      const token = data.token;
      const refresh = data.refresh;
      const tokenDecoded = jwt.decode(token);
      const refreshDecoded = jwt.decode(refresh);
      const accessToken = {
        token: token,
        exp: tokenDecoded.exp,
      };
      const refreshToken = {
        token: refresh,
        exp: refreshDecoded.exp,
      };
      const userId = tokenDecoded._id;
      yield call(authScopeSetHelper, JWT_TOKEN, token);
      yield call(authScopeSetHelper, JWT_REFRESH_TOKEN, refresh);
      yield call(addHeaderToken, token);
      const profileData = yield call(attemptFetchProfile, userId);
      if (profileData) yield put(setMineProfile(profileData.data));
      yield put(
        fetchUserSuccess({
          jwtToken: accessToken,
          refreshToken: refreshToken,
          userId,
        })
      );
    }
    if (payload.handleResponseSuccess) {
      yield call(payload.handleResponseSuccess);
    }
    yield put(fetchRegisterUserSuccess());
  } catch (e) {
    console.dir(e);
    let type = "server";
    if (
      e?.response?.data?.toString() === "User with email already exists!" ||
      e?.response?.data?.toString() === '"email" must be a valid email'
    ) {
      type = "mail";
    } else if (
      e?.response?.data?.toString() === "User with PIB already exists!"
    ) {
      type = "PIB";
    }
    const error = {
      error: e,
      type,
    };
    if (payload.handleResponseError) {
      yield call(payload.handleResponseError, error);
    }
    if (e.response && e.response.data) {
      console.log(e.response.status);
      let errorMessage = yield call(rejectErrorCodeHelper, e.response.status);
      if (e.response.status === 400 || e.response.status === 404) {
        errorMessage = i18next.t("login.wrongCredentials", {
          lng: "rs",
        });
      }
      yield put(fetchUserError(errorMessage));
    }
    yield put(fetchRegisterUserError());
  }
}

export default function* registerSaga() {
  yield all([takeLatest(REGISTER_USER_FETCH, fetchRegisterUser)]);
}
