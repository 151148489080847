import React from "react";
import PropTypes from "prop-types";
import {
  OfferDescriptionContainer,
  OfferDescriptionText,
  OfferDescriptionTitle,
} from "./OfferDescription.styled";
import { useTranslation } from "react-i18next";
import RichTextComponent from "../../../RichTextComponent/RichTextComponent";
import { isJsonString } from "../../../../util/helpers/jsonHelper";

const OfferDescription = (props) => {
  const { t } = useTranslation();
  return (
    <OfferDescriptionContainer>
      <OfferDescriptionTitle>
        {t("offer.descriptionLabel")}
      </OfferDescriptionTitle>
      <OfferDescriptionText>
        {isJsonString(props?.description) ? (
          <RichTextComponent readOnly offerCard value={props?.description} />
        ) : (
          ""
        )}
      </OfferDescriptionText>
    </OfferDescriptionContainer>
  );
};

OfferDescription.propTypes = {
  description: PropTypes.string,
};

export default OfferDescription;
