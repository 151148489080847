import { Box } from "@mui/material";
import styled from "styled-components";

export const FilterCardContainer = styled(Box)`
  position: fixed;
  box-sizing: border-box;
  border-radius: 0;
  border-top-right-radius: 4px;
  height: ${(props) =>
    props.myOffers ? `calc(100% - 153px)` : `calc(100% - 90px)`};
  padding: ${(props) => (props.skeleton ? "0" : "36px")};
  background-color: white;
  width: ${(props) => (!props.payments ? "calc(100% / 12 * 3.5)" : "209px")};
  ${(props) => !props.payments && `left: 0; margin-top: -24px;`}
  ${(props) => props.payments && "top: 0;"}
  bottom: 0;
  max-width: 360px;
  display: ${(props) =>
    props.responsive && !props.responsiveOpen ? "none" : "flex"};
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  min-width: fit-content;
  min-width: 285px !important;
  z-index: 9;
  transition: all ease-in-out 1s;
  transition: padding 0s;

  & header {
    position: absolute;
    top: -73px;
  }
  @media (max-width: 900px) {
    ${(props) => !props.payments && `margin-left: -400px;`}

    ${(props) =>
      props.filtersOpened &&
      `
        display: "flex";
        margin-left: 0;
        max-width: 100vw;
        width: 100vw;
        bottom: 0;
        height: calc(100% - 50px);
        `};
    transition: all ease-in-out 0.36s;
  }
  & * {
    ${(props) => props.skeleton && "display: none;"}
  }
  @media (max-width: 600px) {
    margin-top: -14px;
  }
`;
export const ContentContainer = styled(Box)`
  overflow-y: auto;
  height: 100%;
  margin-bottom: 1.8rem;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
  scrollbar-width: thin;
  scrollbar-color: #ddd;
  ${() => window.scrollbars.visible && `padding-right: 15px`};
`;
