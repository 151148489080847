export const sortEnum = {
  INITIAL: {
    value: 0,
    mainText: "Sortiraj po",
    queryString: "",
  },
  POPULAR: {
    value: 1,
    mainText: "Najpopularnije",
    queryString: "popular",
  },
  NEW: {
    value: 2,
    mainText: "Najnovije",
    queryString: "newest",
  },
  OLD: {
    value: 3,
    mainText: "Najstarije",
    queryString: "oldest",
  },
};
export const sortAdminEnum = {
  INITIAL: {
    value: 0,
    mainText: "Sortiraj po",
  },
  GIVEN: {
    value: 1,
    mainText: "Date",
  },
  RECIEVED: {
    value: 2,
    mainText: "Dobijene",
  },
};
export const sortCategoriesEnum = {
  INITIAL: {
    value: 0,
    mainText: "Sortiraj po",
    queryString: "",
  },
  POPULAR: {
    value: 1,
    mainText: "Najpopularnije",
    queryString: "popular",
  },
  NEW: {
    value: 2,
    mainText: "Najskorije dodate",
    queryString: "newest",
  },
  OLD: {
    value: 3,
    mainText: "Najstarije dodate",
    queryString: "oldest",
  },
};
export const sortUsersEnum = {
  INITIAL: {
    value: 0,
    mainText: "Sortiraj po",
    queryString: "",
  },
  NEW: {
    value: 1,
    mainText: "Najskorije registrovan",
    queryString: "newest",
  },
  OLD: {
    value: 2,
    mainText: "Najstarije registrovan",
    queryString: "oldest",
  },
};
export const sortPaymentsEnum = {
  INITIAL: {
    value: 0,
    mainText: "Sortiraj po",
    queryString: "",
  },
  NEW: {
    value: 1,
    mainText: "Najskorije",
    queryString: "newest",
  },
  OLD: {
    value: 2,
    mainText: "Najstarije",
    queryString: "oldest",
  },
};
