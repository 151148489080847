import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const MiniChatCardContainer = styled(Box)`
  background-color: ${props => props.selected ? selectedTheme.colors.primaryPurple : "white"};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  min-height: 108px;
  margin-bottom: 18px;
  padding: 18px;
  cursor: pointer;
`;
export const ProfileImage = styled.img`
  width: 72px;
  height: 72px;
  min-width: 72px;
  min-height: 72px;
  border-radius: 100%;
  overflow: hidden;
`;
export const ProfileDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  padding-top: 7px;
`;
export const ProfileNameContainer = styled(Box)`
  max-height: 54px;
  min-height: 38px;
`
export const ProfileName = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  font-family: ${selectedTheme.fonts.textFont};
  color: ${props => props.selected ? selectedTheme.colors.primaryYellow : selectedTheme.colors.primaryPurple};
  max-height: 38px;
`;
export const ProfileProduct = styled(Typography)`
  margin-top: 9px;
  font-size: 9px;
  color: ${props => props.selected ? "white" : selectedTheme.colors.primaryDarkTextThird};
  font-family: ${selectedTheme.fonts.textFont};
  line-height: 10px;
  margin-left: 1px;
`;
export const ProfileProductName = styled(Typography)`
  font-size: 12px;
  font-weight: ${props => props.selected ? "400" : "600"};
  color: ${props => props.selected ? "white" : selectedTheme.colors.primaryDarkTextThird};
  font-family: ${selectedTheme.fonts.textFont};
  line-height: 14px;
  max-height: 14px;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 1px;
`;
