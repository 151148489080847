import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { ReactComponent as Swaps } from "../../../assets/images/svg/swaps.svg";
import { ReactComponent as User } from "../../../assets/images/svg/user.svg";
import { ReactComponent as Category } from "../../../assets/images/svg/category.svg";
import { ReactComponent as Subcategory } from "../../../assets/images/svg/subcategory.svg";
import { ReactComponent as Location } from "../../../assets/images/svg/location.svg";
import { ReactComponent as Payment } from "../../../assets/images/svg/dollar-sign.svg";
import { ReactComponent as Filter } from "../../../assets/images/svg/filter.svg";

export const HeaderWrapperContainer = styled(Box)`
  display: ${(props) => (props.skeleton ? "none" : "block")};
  position: relative;
  top: ${(props) => props.isAdmin && `60px`};
`;

export const HeaderContainer = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderOptions = styled(Box)`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: end;

  @media (max-width: 320px) {
    width: 180px;
  }
`;

export const IconStyled = styled(Box)`
  position: relative;
  top: 0;
  right: 10px;
`;

export const RefreshIcon = styled(Swaps)`
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
  left: -5px;
  & path {
    stroke: ${selectedTheme.colors.primaryDarkTextThird};
  }
`;
export const MySwapsTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  color: ${selectedTheme.colors.primaryDarkTextThird};
  position: relative;
  left: 9px;
`;
export const CategoryIcon = styled(Category)`
  position: relative;
  top: 4px;
  right: 2px;
`;
export const SubcategoryIcon = styled(Subcategory)`
  position: relative;
  top: 4px;
  right: 2px;
`;
export const PageTitleContainer = styled(Box)`
  position: relative;
  left: 6px;
  margin-top: 36px;
  width: 100px;
  @media (max-width: 600px) {
    & svg {
      width: 12px;
      height: 12px;
      top: 2px;
    }
  }
`;
export const SwapsIcon = styled(RefreshIcon)`
  width: 12px;
  height: 12px;
  top: 1px;
`;
export const SwapsTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 12px;
  line-height: 16px;
  color: ${selectedTheme.colors.primaryText};
`;

export const SwapsHeaderIcon = styled(SwapsIcon)`
  width: 18px;
  height: 18px;
`;
export const UserIcon = styled(User)`
  position: relative;
  top: 3px;
  margin-right: 5px;
  & path {
    stroke: ${selectedTheme.colors.primaryText};
  }
`;
export const LocationIcon = styled(Location)`
  position: relative;
  top: 3px;
  margin-right: 5px;
  & path {
    stroke: ${selectedTheme.colors.primaryText};
  }
`;

export const PaymentsIcon = styled(Payment)`
  position: relative;
  top: 3px;
  margin-right: 5px;
  & path {
    stroke: ${selectedTheme.colors.primaryText};
  }
`;

export const FilterButtonContainer = styled(Box)`
  background-color: #e4e4e4;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 18px;
  cursor: pointer;

  @media (max-width: 600px) {
    margin-top: 25px;
  }
`;

export const FilterButtonIcon = styled(Filter)`
  width: 20px;
  path {
    stroke-width: 2px;
  }
`;
