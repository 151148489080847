import React from "react";
import PropTypes from "prop-types";
import {
  Details,
  OfferDescriptionTitle,
  OfferImage,
  OfferLittleDetails,
  OfferTitle,
  ScrollerHorizontal,
  ScrollerVertical,
  OfferInfoContainer,
  DesciprtionPostDate,
} from "./OfferDetails.styled";
import { useTranslation } from "react-i18next";
import { formatDateLocale } from "../../../../util/helpers/dateHelpers";
import useIsMobile from "../../../../hooks/useIsMobile";
import { getImageUrl, variants } from "../../../../util/helpers/imageUrlGetter";
import { useEffect } from "react";
import { useState } from "react";
import ImagesCarousel from "../ImagesCarousel/ImagesCarousel";
import OfferDescription from "./OfferDescription/OfferDescription";

const OfferDetails = (props) => {
  const offer = props.offer;
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const [images, setImages] = useState([]);
  const [imagesCarouselModal, setImagesCarouselModal] = useState(false);

  useEffect(() => {
    if (props?.offer?.images) {
      props.offer.images.map((file) => {
        if (file) {
          if (typeof file !== "string") {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
              setImages((prevImages) => [...prevImages, reader.result]);
            };
            reader.onerror = function (error) {
              console.log("Error: ", error);
            };
          } else {
            setImages((prevImages) => [
              ...prevImages,
              getImageUrl(file, variants.offerCard, isMobile),
            ]);
          }
        }
      });
    }
    return () => {
      setImages([]);
    };
  }, [props?.offer?.images]);
  const date = formatDateLocale(new Date(offer?._created));
  const onModalClose = () => {
    setImagesCarouselModal(false);
  };
  console.log(props);
  return (
    <>
      <Details
        hasScrollBar={!props.showPublishButton}
        exchange={props.showExchangeButton}
        singleOffer={props.singleOffer}
        previewCard={props.previewCard}
      >
        {!isMobile && props.singleOffer && (
          <ScrollerVertical>
            {images.map((item, index) => (
              <OfferImage
                src={images[index]}
                alt={t("offer.imageAlt")}
                key={item}
                previewCard={props.previewCard}
                onClick={() =>
                  !props.previewCard && setImagesCarouselModal(true)
                }
              />
            ))}
          </ScrollerVertical>
        )}
        <OfferInfoContainer
          singleOffer={props.singleOffer}
          previewCard={props.previewCard}
        >
          <OfferTitle singleOffer={props.singleOffer}>{offer?.name}</OfferTitle>
          {isMobile && (
            <ScrollerHorizontal>
              {images.map((item, index) => {
                if (!item) return;
                return (
                  <OfferImage
                    src={images[index]}
                    key={item}
                    previewCard={props.previewCard}
                    onClick={() =>
                      !props.previewCard && setImagesCarouselModal(true)
                    }
                  />
                );
              })}
            </ScrollerHorizontal>
          )}
          <OfferLittleDetails
            singleOffer={props.singleOffer}
            previewCard={props.previewCard}
          >
            <OfferDescriptionTitle>
              {t("itemDetailsCard.description")}
            </OfferDescriptionTitle>
            <OfferDescription value={offer?.description} />
            <DesciprtionPostDate previewCard={props.previewCard}>
              {date}
            </DesciprtionPostDate>
          </OfferLittleDetails>
        </OfferInfoContainer>
      </Details>
      {imagesCarouselModal && (
        <ImagesCarousel
          offer={props.offer}
          images={images}
          onModalClose={onModalClose}
        />
      )}
    </>
  );
};

OfferDetails.propTypes = {
  offer: PropTypes.any,
  showExchangeButton: PropTypes.bool,
  showPublishButton: PropTypes.bool,
  singleOffer: PropTypes.bool,
  previewCard: PropTypes.bool,
  createOffer: PropTypes.bool,
};

export default OfferDetails;
