import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  OfferCardContainerMobile,
  OfferTextMobile,
  OfferTitleMobile,
} from "./MobileOfferDetails.styled";

const MobileOfferDetails = (props) => {
  const { t } = useTranslation();
  return (
    <OfferCardContainerMobile>
      <OfferTextMobile>{t("messages.cardProduct")}</OfferTextMobile>
      <OfferTitleMobile>{props.chat?.offer?.name}</OfferTitleMobile>
    </OfferCardContainerMobile>
  );
};

MobileOfferDetails.propTypes = {
  chat: PropTypes.any,
};

export default MobileOfferDetails;
