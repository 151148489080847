import styled from "styled-components";
import selectedTheme from "../../../../themes";
import IconWithNumber from "../../../Icon/IconWithNumber/IconWithNumber";
import { ReactComponent as Filter } from "../../../../assets/images/svg/filter.svg";

export const FilterContainer = styled(IconWithNumber)`
  position: absolute;
  top: 93px;
  right: 24px;
  cursor: pointer;
  background-color: ${selectedTheme.colors.primaryBackgroundColor} !important;
  & div {
    width: 16px;
    height: 16px;
    background-color: ${selectedTheme.colors.primaryPurple};
    position: absolute;
    top: -5px;
    right: -5px;
    line-height: 15px;
    text-align: center;
  }

  @media (min-width: 600px) {
    display: none;
  }
`;
export const FilterIcon = styled(Filter)`
  background-color: ${selectedTheme.colors.primaryBackgroundColor};
`;
