import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  RegisterPageContainer,
  Footer,
  FooterText,
  LoginAltText,
  LoginTextContainer,
  ProgressContainer,
  RegisterDescription,
  RegisterTitle,
  ProfileImagePicker,
  ProfilePicture,
  RegisterPageContent,
  ErrorMessage,
} from "./Register.styled";
import { ReactComponent as Logo } from "../../../assets/images/svg/logo-vertical.svg";
import { NavLink, useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Link from "../../../components/Link/Link";
import StepProgress from "../../../components/StepProgress/StepProgress";
import { REGISTER_SUCCESSFUL_PAGE } from "../../../constants/pages";
import FirstPartOfRegistration from "./FirstPart/FirstPartOfRegistration";
import SecondPartOfRegistration from "./SecondPart/SecondPartOfRegistration";
import ThirdPartOfRegistration from "./ThirdPart/ThirdPartOfRegistration";
import { useDispatch, useSelector } from "react-redux";
import { fetchRegisterUser } from "../../../store/actions/register/registerActions";
import { makeErrorToastMessage } from "../../../store/utils/makeToastMessage";
import { selectIsLoadingByActionType } from "../../../store/selectors/loadingSelectors";
import { REGISTER_USER_SCOPE } from "../../../store/actions/register/registerActionConstants";

const Register = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [informations, setInformations] = useState({}); // Values of fields typed in all steps
  const [mailError, setMailError] = useState(""); // Wrong mail typed
  const [mailErrorMessage, setMailErrorMessage] = useState(""); // Error message caused by typing wrong mail
  const [PIBError, setPIBError] = useState(""); // Wrong PIB typed
  const [PIBErrorMessage, setPIBErrorMessage] = useState(""); // Error message caused by typing wrong PIB
  const [companyNameError, setCompanyNameError] = useState("");
  const [imageError, setImageError] = useState(false); // Not picked image
  const isLoadingRegister = useSelector(
    selectIsLoadingByActionType(REGISTER_USER_SCOPE)
  );

  const handleResponseSuccess = () => {
    history.push(REGISTER_SUCCESSFUL_PAGE);
  };

  const handleResponseError = (error) => {
    console.dir(error);
    console.log("informations", informations);
    const { mail, registerPassword, PIB, image } = informations;
    if (error.type === "mail") {
      setInformations({ image });
      setCurrentStep(1);
      setMailError(mail);
      if (
        error?.error?.response?.data?.toString() ===
        "User with email already exists!"
      ) {
        setMailErrorMessage(t("register.emailTaken"));
      } else {
        setMailErrorMessage(t("register.emailFormat"));
      }
    } else if (
      error?.error?.response?.data?.toString() ===
      "User with PIB already exists!"
    ) {
      setInformations({ mail, registerPassword, image });
      setCurrentStep(2);
      setPIBError(PIB.toString());
      setPIBErrorMessage(t("register.PIBTaken"));
    } else if (
      error?.error?.response?.data?.toString() ===
      "Company with that name already exists!"
    ) {
      setInformations({ mail, registerPassword, image });
      setCurrentStep(2);
      setCompanyNameError(t("register.companyNameError"));
    } else makeErrorToastMessage(t("register.serverError"));
  };

  const registerUser = (values) => {
    setCompanyNameError("");
    setPIBErrorMessage("");
    setMailErrorMessage("");
    dispatch(
      fetchRegisterUser({ values, handleResponseSuccess, handleResponseError })
    );
  };

  const handleSubmit = (values) => {
    if (currentStep !== 3) {
      setCurrentStep((prevState) => prevState + 1);
    } else {
      // if (!informations.image) {
      //   setImageError(true);
      // } else {
      registerUser({ ...informations, ...values });
      // }
    }
    setInformations({ ...informations, ...values });
  };

  const setImage = (image) => {
    setImageError(false);
    setInformations((prevInfo) => ({
      ...prevInfo,
      image,
    }));
  };

  const goStepBack = (stepNumber) => {
    setCurrentStep(stepNumber);
    // const { mail, password, image } = informations;
    // if (stepNumber === 1) {
    //   setInformations({ image });
    // }
    // if (stepNumber === 2) {
    //   setInformations({ mail, password, image });
    // }
  };

  return (
    <RegisterPageContainer currentstep={currentStep}>
      <RegisterPageContent>
        <Logo />

        <RegisterTitle component="h1" variant="h5">
          {t("register.title")}
        </RegisterTitle>

        <RegisterDescription component="h1" variant="h6">
          {t("register.descriptionMain")}
        </RegisterDescription>

        <ProgressContainer>
          <StepProgress
            functions={[() => goStepBack(1), () => goStepBack(2)]}
            current={currentStep}
            numberOfSteps={3}
          />
        </ProgressContainer>

        <ProfileImagePicker setImage={setImage} singleImage>
          <ProfilePicture />
        </ProfileImagePicker>

        {currentStep === 1 && (
          <FirstPartOfRegistration
            handleSubmit={handleSubmit}
            informations={informations}
            error={mailError}
            errorMessage={mailErrorMessage}
          />
        )}
        {currentStep === 2 && (
          <SecondPartOfRegistration
            handleSubmit={handleSubmit}
            informations={informations}
            error={PIBError}
            errorMessage={PIBErrorMessage}
            companyNameError={companyNameError}
          />
        )}
        {currentStep === 3 && (
          <ThirdPartOfRegistration
            handleSubmit={handleSubmit}
            informations={informations}
            isLoading={isLoadingRegister}
          />
        )}

        {imageError && <ErrorMessage>{t("register.imageError")}</ErrorMessage>}

        <LoginTextContainer>
          <LoginAltText>{t("register.loginText")}</LoginAltText>

          <Link
            to="/login"
            component={NavLink}
            underline="hover"
            align="center"
          >
            {t("register.login")}
          </Link>
        </LoginTextContainer>
      </RegisterPageContent>

      <Footer currentStep={currentStep}>
        <FooterText>
          <Trans i18nKey="register.acceptTerms" />{" "}
          <NavLink
            to="#"
            style={{ color: "black", fontWeight: "500", cursor: "pointer" }}
          >
            <Trans i18nKey="register.terms" />
          </NavLink>
        </FooterText>
      </Footer>
    </RegisterPageContainer>
  );
};

Register.propTypes = {
  children: PropTypes.node,
};

export default Register;
