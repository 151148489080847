import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  LOGIN_PAGE,
  ADMIN_LOGIN_PAGE,
  HOME_PAGE,
  NOT_FOUND_PAGE,
  ERROR_PAGE,
  BASE_PAGE,
  FORGOT_PASSWORD_MAIL_SENT,
  REGISTER_PAGE,
  REGISTER_SUCCESSFUL_PAGE,
  RESET_PASSWORD_PAGE,
  CREATE_OFFER_PAGE,
  ITEM_DETAILS_PAGE,
  FORGOT_PASSWORD_PAGE,
  PROFILE_PAGE,
  MY_OFFERS_PAGE,
  ABOUT_PAGE,
  ADMIN_HOME_PAGE,
  MESSAGES_LIST_PAGE,
  DIRECT_CHAT_PAGE,
  MARKETPLACE_PAGE,
} from "./constants/pages";
import LoginPage from "./pages/LoginPage/LoginPage";
import AdminLoginPage from "./pages/AdminLoginPage/AdminLoginPage";
import HomePage from "./pages/HomePage/HomePage";
import NotFoundPage from "./pages/ErrorPages/NotFoundPage";
import ErrorPage from "./pages/ErrorPages/ErrorPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import PrivateRoute from "./components/Router/PrivateRoute";
import MailSent from "./pages/ForgotPasswordPage/ForgotPasswordMailSent/MailSent";
import Register from "./pages/RegisterPages/Register/Register";
import RegisterSuccessful from "./pages/RegisterPages/RegisterSuccessful.js/RegisterSuccessful";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import CreateOffer from "./pages/CreateOffer/CreateOffer";
import ItemDetailsPage from "./pages/ItemDetailsPage/ItemDetailsPageMUI";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import DirectChatPage from "./pages/DirectChatPage/DirectChatPage";
import MessagesListPage from "./pages/MessagesListPage/MessagesListPage";
import MyOffers from "./pages/MyOffers/MyOffers";
import AboutPage from "./pages/About/AboutPage";
import AuthRoute from "./components/Router/AuthRoute";
import AdminRoute from "./components/Router/AdminRoute";
import AdminHomePage from "./pages/AdminHomePage/AdminHomePage";
import MarketplacePage from "./pages/Marketplace/MarketplacePage";

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path={BASE_PAGE} component={HomePage} />
      <AuthRoute exact path={LOGIN_PAGE} component={LoginPage} />
      <AuthRoute exact path={ADMIN_LOGIN_PAGE} component={AdminLoginPage} />
      <AuthRoute exact path={REGISTER_PAGE} component={Register} />
      <AuthRoute path={FORGOT_PASSWORD_MAIL_SENT} component={MailSent} />
      <AuthRoute path={FORGOT_PASSWORD_PAGE} component={ForgotPasswordPage} />
      <AuthRoute path={RESET_PASSWORD_PAGE} component={ResetPasswordPage} />
      <Route path={REGISTER_SUCCESSFUL_PAGE} component={RegisterSuccessful} />
      <Route path={MARKETPLACE_PAGE} component={MarketplacePage} />
      <Route path={CREATE_OFFER_PAGE} component={CreateOffer} />
      <Route path={ITEM_DETAILS_PAGE} component={ItemDetailsPage} />
      <Route path={PROFILE_PAGE} component={ProfilePage} />
      <Route path={ABOUT_PAGE} component={AboutPage} />
      <Route path={HOME_PAGE} component={HomePage} />
      <PrivateRoute
        exact
        path={MESSAGES_LIST_PAGE}
        component={MessagesListPage}
      />
      <PrivateRoute path={DIRECT_CHAT_PAGE} component={DirectChatPage} />
      <PrivateRoute path={MY_OFFERS_PAGE} component={MyOffers} />
      <AdminRoute path={ADMIN_HOME_PAGE} component={AdminHomePage} />
      <Route path={ERROR_PAGE} component={ErrorPage} />
      <Route path={NOT_FOUND_PAGE} component={NotFoundPage} />
      <Redirect from="*" to={NOT_FOUND_PAGE} />
    </Switch>
  );
};

export default AppRoutes;
