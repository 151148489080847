import {
  deleteRequest,
  getRequest,
  patchRequest,
  putRequest,
  replaceInUrl,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchProfile = (payload) =>
  getRequest(apiEndpoints.users.getProfile + payload);

export const attemptFetchAllProfiles = () =>
  getRequest(apiEndpoints.users.getAllProfiles);
export const attemptFetchAllProfilesAsAdmin = (payload) =>
  getRequest(apiEndpoints.users.getAllProfilesAsAdmin + "?" + payload);

export const attemptEditProfile = (payload, requestData) => {
  return putRequest(
    replaceInUrl(apiEndpoints.users.editProfile, { userId: payload }),
    requestData
  );
};
export const attemptEditProfileAsAdmin = (payload, requestData) =>
  putRequest(
    replaceInUrl(apiEndpoints.users.editProfileAsAdmin, { userId: payload }),
    requestData
  );
export const attemptDeleteProfileAsAdmin = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.users.deleteProfileAsAdmin, {
      userId: payload.userId,
    })
  );
export const attemptBlockProfileAsAdmin = (payload) =>
  patchRequest(
    replaceInUrl(apiEndpoints.users.blockProfileAsAdmin, {
      userId: payload.userId,
    })
  );
export const attemptUnblockProfileAsAdmin = (payload) =>
  patchRequest(
    replaceInUrl(apiEndpoints.users.unblockProfileAsAdmin, {
      userId: payload.userId,
    })
  );
