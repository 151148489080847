import React from "react";
import PropTypes from "prop-types";
import {
  ProfileMainInfoContainer,
  AvatarImageContainer,
  AvatarImage,
  ProfileMainInfoGrid,
  ProfileName,
  ProfilePIBContainer,
  PocketIcon,
  ProfilePIB,
  VerifiedUserContainer,
  // BlockedProfileText,
} from "./ProfileMainInfo.styled";
import { useTranslation } from "react-i18next";
import { getImageUrl, variants } from "../../../../util/helpers/imageUrlGetter";
import useIsMobile from "../../../../hooks/useIsMobile";
import history from "../../../../store/utils/history";
import {
  isAdminRoute,
  replaceInRoute,
} from "../../../../util/helpers/routeHelpers";
import { ADMIN_SINGLE_USER_PAGE } from "../../../../constants/pages";
import BlockedProfile from "../BlockedProfile/BlockedProfile";
import { ReactComponent as VerifiedIcon } from "../../../../assets/images/svg/verified-user.svg";
import { Tooltip } from "@mui/material";

const ProfileMainInfo = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const goToUser = () => {
    if (isAdminRoute()) {
      history.push(
        replaceInRoute(ADMIN_SINGLE_USER_PAGE, {
          profileId: props.profile?._id,
        })
      );
    }
  };
  return (
    <ProfileMainInfoContainer
      bigProfileCard={props.bigProfileCard}
      isAdmin={props.isAdmin}
    >
      <AvatarImageContainer>
        <AvatarImage
          isAdmin={props.isAdmin}
          src={getImageUrl(
            props.profile?.image,
            variants.profileImage,
            isMobile
          )}
        />
      </AvatarImageContainer>
      <ProfileMainInfoGrid bigProfileCard={props.bigProfileCard}>
        {props.profile?._blocked &&
          props.isAdmin &&
          (!isMobile || (isMobile && props.bigProfileCard)) && (
            <BlockedProfile hideIcon redText aboveTitle isAdmin />
          )}
        <ProfileName
          bigProfileCard={props.bigProfileCard}
          isAdmin={props.isAdmin}
          isMyProfile={props.isMyProfile}
          isBlocked={props.isBlocked}
          variant="h5"
          onClick={goToUser}
        >
          {props.profile?.company?.name}
          {props.verifiedUser && (
            <Tooltip title={t("profile.verifiedTooltip")} placement="right">
              <VerifiedUserContainer>
                <VerifiedIcon />
              </VerifiedUserContainer>
            </Tooltip>
          )}
        </ProfileName>
        <ProfilePIBContainer>
          <PocketIcon />
          <ProfilePIB
            isMyProfile={props?.isBlocked || props.isMyProfile}
            isBlocked={props.isBlocked}
            variant="subtitle2"
          >
            {t("profile.PIB")} {props.profile?.company?.PIB}
          </ProfilePIB>
        </ProfilePIBContainer>
      </ProfileMainInfoGrid>
    </ProfileMainInfoContainer>
  );
};

ProfileMainInfo.propTypes = {
  profile: PropTypes.object,
  isMyProfile: PropTypes.bool,
  children: PropTypes.node,
  isAdmin: PropTypes.any,
  bigProfileCard: PropTypes.bool,
  isBlocked: PropTypes.bool,
  verifiedUser: PropTypes.bool,
};
ProfileMainInfo.defaultProps = {
  isAdmin: false,
  bigProfileCard: false,
  isBlocked: false,
  isMyProfile: false,
};

export default ProfileMainInfo;
