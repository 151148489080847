import { all, takeLatest, call, put, select } from "@redux-saga/core/effects";
import {
  attemptAcceptExchange,
  attemptFetchExchange,
  attemptValidateExchange,
} from "../../request/exchangeRequest";
import {
  EXCHANGE_ACCEPT_FETCH,
  EXCHANGE_FETCH,
  EXCHANGE_VALIDATE_FETCH,
} from "../actions/exchange/exchangeActionConstants";
import {
  acceptExchangeError,
  acceptExchangeSuccess,
  fetchExchangeError,
  fetchExchangeSuccess,
  setExchange,
  validateExchangeError,
  validateExchangeSuccess,
} from "../actions/exchange/exchangeActions";
import { selectUserId } from "../selectors/loginSelectors";

function* fetchExchange(payload) {
  try {
    const data = yield call(attemptFetchExchange, payload.payload);
    yield put(setExchange(data.data));
    yield put(fetchExchangeSuccess());
  } catch (e) {
    yield put(fetchExchangeError());
    console.dir(e);
  }
}

function* validateExchange(payload) {
  try {
    yield call(attemptValidateExchange, payload.payload);
    const data = yield call(attemptFetchExchange, payload.payload);
    yield put(setExchange(data.data));
    yield put(validateExchangeSuccess());
  } catch (e) {
    yield put(validateExchangeError());
    console.dir(e);
  }
}
function* acceptExchange({ payload }) {
  try {
    console.log("accept salje i prima 2 POZVANA ACCEPT SAGA")
    const userId = yield select(selectUserId);
    yield call(attemptAcceptExchange, userId, payload.exchangeId);
    const data = yield call(attemptFetchExchange, payload.exchangeId);
    yield put(setExchange(data.data));
    if (payload.handleApiResponseSuccess)
      yield call(payload.handleApiResponseSuccess);
    yield put(acceptExchangeSuccess());
  } catch (e) {
    yield put(acceptExchangeError());
    console.dir(e);
  }
}

export default function* exchangeSaga() {
  yield all([
    takeLatest(EXCHANGE_FETCH, fetchExchange),
    takeLatest(EXCHANGE_VALIDATE_FETCH, validateExchange),
    takeLatest(EXCHANGE_ACCEPT_FETCH, acceptExchange),
  ]);
}
