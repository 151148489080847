import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import Link from "../Link/Link";
import { ReactComponent as LogoImageSvg } from "../../assets/images/svg/logo-image-round.svg";

export const DrawerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25px;
  & div {
    flex-direction: column;
  }
`;

export const LogoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  max-width: 130px;
  svg {
    width: 90%;
  }
  @media (min-width: 800px) {
    position: relative;
    left: 15px;
  }
`;
export const ToolsButtonsContainer = styled(Box)`
  display: flex;
  flex: ${(props) => (props.shrink ? "none" : "4")};
  justify-content: flex-end;
  gap: ${(props) => (props.isTablet ? "15px" : "2.5rem")};
  min-width: ${(props) =>
    props.mobile ? "40px" : props.shrink ? "0" : "600px"};
  max-width: 600px;
  align-items: center;
  flex-wrap: nowrap;
  @media (max-width: 1400px) {
    min-width: ${(props) => (props.shrink ? "0" : "450px")};
  }
  @media (max-width: 1200px) {
    min-width: ${(props) => (props.shrink ? "0" : "400px")};
  }
  @media (max-width: 900px) {
    flex: 0.3;
    min-width: 0px;
    width: 60px;
    justify-content: right;
  }
`;
export const ToolsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.mobile ? "center" : "space-between")};
  align-items: ${(props) => (props.mobile ? "start" : "center")};
  ${(props) => !props.mobile && `width: 100%;`}
  & div button {
    ${(props) => props.mobile && `width: auto;`}
  }
`;

export const AuthButtonsContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  min-width: ${(props) => (props.mobile ? "40px" : "200px")};
  max-width: 600px;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-left: 40px;
  & div {
    margin-left: 20px;
  }
  @media (max-width: 1300px) {
    margin-left: 0;
  }
  @media (max-width: 1200px) {
    min-width: 400px;
  }
  @media (max-width: 900px) {
    min-width: 0px;
    width: 0px;
    justify-content: right;
  }
`;

export const AuthButtonsDrawerContainer = styled(Box)`
  position: relative;
  left: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
`;
export const HeaderContainer = styled(Box)``;

export const MarketplaceLinkRouteContainer = styled(Box)`
  display: flex;

  @media (max-width: 1200px) {
    position: relative;
    right: -200px;
  }
`;

export const MarketplaceLinkRoute = styled(Link)`
  text-decoration: none;
  font-family: ${selectedTheme.fonts.textFont};
  font-size: 16px;
  line-height: 22px;
  margin-right: 10px;
  letter-spacing: 0.02em;
  margin-left: 70px;
  border-bottom: 1px dashed ${selectedTheme.colors.primaryPurple};
  &:hover {
    border-bottom: 1px solid ${selectedTheme.colors.iconYellowColor};
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const LogoImage = styled(LogoImageSvg)`
  width: 40px;
`;

export const SmallScreenMessagesContainer = styled(Box)``;
