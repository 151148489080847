import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import "./index.css";
import App from "./App";
import { store, persistor } from "./store";
import GlobalStyle from "./themes/primaryTheme/globalStyles";

import "./i18n";
import ColorModeProvider from "./context/ColorModeContext";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <Provider store={store}>
        <ColorModeProvider>
          <PersistGate loading={null} persistor={persistor}>
            <GlobalStyle />
            <App />
          </PersistGate>
        </ColorModeProvider>
      </Provider>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById("root")
);
