import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  CreateOfferFormContainer,
  SupportedFormats,
} from "./SecondPartCreateOffer.styled";
import { Trans } from "react-i18next";
import { useFormik } from "formik";
import { useEffect } from "react";
import OfferImagePicker from "./OfferImagePicker/OfferImagePicker";
import OfferConditionField from "./OfferConditionField/OfferConditionField";
import NextButton from "./NextButton/NextButton";
import secondPartCreateOfferValidation from "../../../../validations/createOfferValidation/secondPartCreateOfferValidation";
import secondPartCreateOfferInitialValues from "../../../../initialValues/createOfferInitialValues/secondPartCreateOfferInitialValues";

const numberOfImages = 3;

const SecondPartCreateOffer = (props) => {
  const [images, setImages] = useState(
    Array.apply(null, Array(numberOfImages)).map(() => {})
  ); // 3 images

  const initialValues = useMemo(() => {
    return secondPartCreateOfferInitialValues(
      props?.informations,
      props?.offer,
      images
    );
  }, [props?.informations, props?.offer]);

  useEffect(() => {
    formik.setFieldValue("images", images);
  }, [images]);

  useEffect(() => {
    if (props?.offer?.images || props?.informations?.images) {
      const oldImages = props?.informations?.images || props?.offer?.images;
      let newImages = [...images];
      oldImages.forEach((newImage, index) => {
        newImages[index] = newImage;
      });
      setImages([...newImages]);
    }
  }, [props?.offer, props?.informations]);

  const setImage = (index, image) => {
    setImages((prevState) => {
      let newState = [...prevState];
      newState[index] = image;
      return [...newState];
    });
  };

  //How many images are empty
  const imagesEmpty = useMemo(() => {
    let numOfImagesEmpty = 0;
    images.forEach((item) => {
      if (!item) numOfImagesEmpty++;
    });
    return numOfImagesEmpty;
  }, [images]);

  const handleSubmit = (values) => {
    props.handleNext(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: secondPartCreateOfferValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <>
      <CreateOfferFormContainer component="form" onSubmit={formik.handleSubmit}>
        <OfferImagePicker images={images} setImage={setImage} />
        <SupportedFormats>
          <Trans i18nKey="offer.supportedImagesFormats" />
        </SupportedFormats>
        <OfferConditionField formik={formik} />
      </CreateOfferFormContainer>
      <NextButton
        formik={formik}
        offer={props.offer}
        imagesEmpty={imagesEmpty}
        numberOfImages={numberOfImages}
      />
    </>
  );
};

SecondPartCreateOffer.propTypes = {
  children: PropTypes.node,
  handleNext: PropTypes.func,
  offer: PropTypes.node,
  informations: PropTypes.any,
};

export default SecondPartCreateOffer;
