import { REGISTER_USER_FETCH, REGISTER_USER_FETCH_ERROR, REGISTER_USER_FETCH_SUCCESS } from "./registerActionConstants";

export const fetchRegisterUser = (payload) => ({
    type: REGISTER_USER_FETCH,
    payload
})
export const fetchRegisterUserSuccess = () => ({
    type: REGISTER_USER_FETCH_SUCCESS
})
export const fetchRegisterUserError = () => ({
    type: REGISTER_USER_FETCH_ERROR
})